// OK to keep for now, but likely reducing to just Sale Pending and Sold
const uncheckedStatuses = ["Removed", "Sale Pending", "Sold", "Offer Accepted"]
const envMap = {
    PROD: "PROD",
    DEV: "DEV",
    LOCAL: "LOCAL",
    NONPROD: "NONPROD"
}
const resortNames = {
    "Grand Floridian Resort": "Grand Floridian",
    "Bay Lake Tower": "Bay Lake Tower",
    "Animal Kingdom Villas": "Animal Kingdom",
    "Polynesian Villas & Bungalows": "Polynesian",
    "Vero Beach Resort": "Vero Beach",
    "Hilton Head Island Resort": "Hilton Head",
    "BoardWalk Villas": "BoardWalk",
    "Grand Californian Villas": "Grand Californian",
    "Copper Creek Villas": "Copper Creek",
    "Old Key West": "Old Key West",
    "Riviera Resort": "Riviera",
    "Aulani Resort": "Aulani",
    "Saratoga Springs Resort": "Saratoga Springs",
    "Beach Club Villas": "Beach Club",
    "Boulder Ridge": "Boulder Ridge"
}

const originNames = {
    dvcresalemarket: "DVC Resale Market",
    dvcshop: "DVC Shop",
    dvcstore: "DVC Store",
    fidelityresales: "Fidelity Real Estate",
    dvcresaleexperts: "DVC Resale Experts",
    dvcsales: "DVC Sales",
    resalesdvc: "Vacation Club Life",
    dvcbyresale: "DVC By Resale",
    vacatia: "Vacatia",
    dvcadvantages: "DVC Advantages",
    buyandselldvc: "Buy & Sell DVC"
}

module.exports = {
    resortNames,
    envMap,
    originNames,
    uncheckedStatuses,
    isBrowser: typeof window !== "undefined",
    staticRevalidateTime: 60 // 1 minute
}
